<template>
    <div class="confirm-user-subscription">
      <div v-if="isLoadingPage">
        <Loader />
      </div>
      <div class="container-fluid">
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-center">
            <div class="logo">
              <img :src="logo" alt="NinaFin logo">
            </div>
          </div>
        </div>
        <div class="pretty-card my-5">

          <div class="row mt-5">
            <div class="col-12" v-if="!showGoToLoginButton && !showError">
              <custom-button
                :isLoading="loading"
                label="Conferma registrazione"
                class="w-100"
                @click.prevent.native="onSubmit"
              />
            </div>
            <div v-else-if="showError">
                <div>Il link è già stato usato :(</div>
                <router-link :to="{ name: 'login' }">
                    <i class="fas fa-chevron-left mr-1"></i> Vai al login
                </router-link>
            </div>
            <div v-else>
                <div>Il suo account ora è attivo!</div>
                <router-link :to="{ name: 'login' }">
                    <i class="fas fa-chevron-left mr-1"></i> Vai al login
                </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
import Loader from '@/views/components/Layout/Loading.vue';
import logo from '@/assets/images/fin-big-logo.svg';
import img from '@/assets/images/illustration.svg';
import Button from '@/views/components/Button.vue';

export default {
  components: {
    Loader,
    'custom-button': Button,
  },
  data () {
    return {
      showError: false,
      isLoadingPage: true,
      loading: false,
      logo: logo,
      img: img,
      showGoToLoginButton: false,
    };
  },
  methods: {
    onSubmit () {
      this.$log.debug('Confirm user subscription');
      this.isLoadingPage = true;
      this.$api.confirmUserSubscription(this.$route.params.email, this.$route.params.token)
        .then((res) => {
          this.$log.debug(res.data);
          this.showGoToLoginButton = true;
        })
        .catch((e) => {
          this.showError = true;
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
  },
  mounted () {
    this.isLoadingPage = false;
  },
};
</script>
<style lang="scss">
  .confirm-user-subscription {
    background-image: url(~@/assets/images/bg-setup.jpg);
    background-color: $primary-light;
    background-size: cover;
    align-items: center;
    .container-fluid {
      max-width: 1200px;
    }
  }
</style>
